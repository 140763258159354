
import { Options, Vue } from 'vue-class-component';
import { CartClient, CommunityClient, EventClient, FieraClient, ShopClient } from '@/services/Services';
import ShopItem from './components/shopItem.vue';
import * as OM from '@/model';
import { Watch } from 'vue-property-decorator';

@Options({
    components: {
        ShopItem
    }
})
export default class Shop extends Vue {

    communityIdentifier: string = "";
    model: OM.ShopAppVM = new OM.ShopAppVM();
    event: OM.EventDetailVm = new OM.EventDetailVm();
    slug: string = "";
    totalItemsInCart: number = 0;

    @Watch("$route.params.slug")
    async mounted() {
        this.slug = this.$route.params.slug.toString();
        var community = await CommunityClient.getBySlug(this.slug)
        this.communityIdentifier = community.identifier;

        var proms = [];

        proms.push(CartClient.getCartItemsCount(this.communityIdentifier));
    
        if(this.isEvent)
            proms.push(ShopClient.getShopItemsByEvent(this.communityIdentifier));
        else 
            proms.push(ShopClient.getShopItemsByCommunity(this.communityIdentifier));
        
        Promise.all(proms)
        .then( xs => {
            this.totalItemsInCart = xs[0];
            this.model = xs[1];

            if(this.$route.query.f){
                setTimeout(() => {
                    let post: any = this.$refs[<string>this.$route.query.f];
                    if(post){
                        post.classList.add('evidenzia');

                        post.scrollIntoView();
                        setTimeout(() => {
                            post.classList.remove('evidenzia')
                        }, 2000)
                    }
                }, 200)
            }
        })

      if(this.$store.state.community.type == 'fiera'){

        //certifica community 
        this.$store.state.community.isCertified = true;

            var itemsInCommunity : OM.NameSlugIdentifier[] = [];

            //prendi tutte le community dallo slug della fiera interne ad essa 
            await FieraClient.getCommunitiesOfFieraBySlug(this.$route.params.slug.toString())
            .then(x => {
                x.forEach(c => {
                    itemsInCommunity = c.items
                });
            })

            //per ogni community presa tira giu tutti gli item nel suo shop se il suo shop, se esistono elementi se no non ciclare, 
            itemsInCommunity.forEach(e =>{
                ShopClient.getShopItemsByCommunity(e.identifier)
                .then(x =>{
                    if(x.baseShopItemsVM.length > 0)
                        x.baseShopItemsVM.forEach(el => {
                            this.model.baseShopItemsVM.push(el)
                        })
                })
            })
        }
    }

    get isEvent(){
        return this.$route.fullPath.indexOf("/events/") != -1;
    }

    openCart() {
        this.$router.push('/communities/' + this.slug + '/Cart');
    }
}
