
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { selfUrl } from '@/config';

@Options({})
export default class ShareButton extends Vue {

    @Prop({
        default: ""
    }) shareUrl: string;

    loaded: boolean = false;
    cordova: boolean = false;

    toShareUrl: string = "";

    created(){
        if(window.cordova)
            this.cordova = true;
    }

    mounted() {
        if(this.shareUrl){
            // this.toShareUrl = selfUrl + "?" + this.shareUrl + "&hashtag=" + this.shareUrl.substr(this.shareUrl.indexOf("#")) + "?open_app_uose=true";
            this.toShareUrl = this.shareUrl;

            this.loaded = true;
            this.$nextTick( () => {
                a2a.init('page')
            })
        }
    }

    share(){
        var cordovaShareUrl = this.toShareUrl;
        var options = {
            url: cordovaShareUrl
        } 

        window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
        var onSuccess = function(result) {
        };

        var onError = function(msg) {
        };
    }

    @Watch("shareUrl")
    changeUrl(){
        this.toShareUrl = this.shareUrl;

        this.loaded = true;
        this.$nextTick( () => {
            a2a.init('page')
        })
    }

}
