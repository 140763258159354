
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { selfUrl } from '@/config';
import ShareButton from '@/components/shareButton.vue';
import * as OM from '@/model';

@Options({
    components: {
        ShareButton
    }
})
export default class ShopItem extends Vue {

    @Prop({
        default: () => new OM.BaseShopItemVM
    }) model: OM.BaseShopItemVM;

    shareUrl: string = "";

    created() {
        var url = this.$route.path;
        this.shareUrl = selfUrl + "#" + url + "?f=" + this.model.identifier;
    }

}

